import React, { lazy, Suspense } from "react"
import { Link } from "gatsby"
import SiteNavigation from "../nav-new/nav"
import Image from "../image-component/image-2023"
import FormSection from "./pricing-form"
import FallbackLoading from "../loader/loader";
import "./pricing-new.scss"
const SliderSection = lazy(() => import('./slider'))
//const FormSection = lazy(() => import('./pricing-form'))

export default function Pricing(props) {
  const isSSR = typeof window === "undefined"
  return (
    <span className={props.wrapperClass}>
      <section className={"hero-pricing"}>
        <SiteNavigation />
        <div className="hero-container">
          <div className="hero-pricing-heading">Pricing</div>
          <div className="hero-pricing-subheading">Three all-inclusive pricing models.</div>
          <div className="pricing-cards">
            <div className="card-item">
              <div className="card-head-wrap">
                <div className="card-heading">Flat Fee</div>
                <div className="card-subheading">Simplicity & budgeting certainty</div>
              </div>
              <div className="card-price bold">$25k <span>/month</span></div>
              <div className="card-description">Simple month-to-month pricing.<br /> Won't confuse finance or legal. No commitment.</div>
              <Link className="arrow-button" to="/get-demo">Get a Demo --></Link>
            </div>
            <div className="card-item">
              <div className="card-head-wrap">
                <div className="card-heading">Performance</div>
                <div className="card-subheading">Pay only after you see results</div>
              </div>
              <div className="card-price">Contact</div>
              <div className="card-description">Fees tied 100% to test performance.<br /> Nothing upfront. You are the sole judge of lift.</div>
              <Link className="arrow-button" to="/get-demo">Get a Demo --></Link>
            </div>
            <div className="card-item">
              <div className="card-head-wrap">
                <div className="card-heading">Pilot</div>
                <div className="card-subheading">A quick proof of concept</div>
              </div>
              <div className="card-price">Contact</div>
              <div className="card-description">Test on a small set of high value pages.<br /> Get quick wins and create organizational buy-in.</div>
              <Link className="arrow-button" to="/get-demo">Get a Demo --></Link>
            </div>
          </div>
        </div>
      </section>
      <section className="social-proof-section">
        <Image
          sourceFolder={'homepage'}
          lazyLoad={"false"}
          alt={"Social proof"}
          fallBackImage={'2023-social-logos-mobile.webp'}
          imgName={[
            {
              imageName: "2023-social-logos-desktop.webp",
              minBreakpoint: "992px",
            },
            {
              imageName: "2023-social-logos-tablet.webp",
              minBreakpoint: "768px",
            },
            {
              imageName: "2023-social-logos-mobile.webp",
              minBreakpoint: "320px",
            },
          ]}
        />
      </section>
      <div className="all-inclusive">
        <div className="all-inclusive-heading">All inclusive pricing</div>
        <div className="all-inclusive-subheading">Pricing includes everything you need to get results. No hidden extras.</div>
        <div className="all-inclusive-cards">
          <div className="card-item">
            <Image
              sourceFolder={'pricing'}
              lazyLoad={"true"}
              alt={"predictive analytics"}
              imgName={"predictive-analytics_n.webp"}
            />
            <div className="card-heading">Predictive analytics</div>
            <div className="card-description">Find proven winners based on data scraped from the 34,000 websites that actively test.</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'pricing'}
              lazyLoad={"true"}
              alt={"full service team"}
              imgName={"full-service-team_n.webp"}
            />
            <div className="card-heading">Full service team</div>
            <div className="card-description">Run lots of tests without the hassles with your own dedicated team.  Design, copywriting, development, QA, analytics, and project management.</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'pricing'}
              lazyLoad={"true"}
              alt={"a/b testing software"}
              imgName={"a-bsoftware.webp"}
            />
            <div className="card-heading">A/B testing software</div>
            <div className="card-description">Unlimited testing bandwidth on an industry leading platform. Integration with GA and your CRM.</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'pricing'}
              lazyLoad={"true"}
              alt={"qualitative research"}
              imgName={"quantitive-research_n.webp"}
            />
            <div className="card-heading">Qualitative research</div>
            <div className="card-description">Qualitative feedback on page performance including heatmapping, session recording, objection polling and more. (Crazyegg, Hotjar).</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'pricing'}
              lazyLoad={"true"}
              alt={"focus groups"}
              imgName={"focus-grou.webp"}
            />
            <div className="card-heading">Panels & focus groups</div>
            <div className="card-description">Detailed feedback from panels and focus groups. Message testing with target customers (Wynter, User Testing).</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'pricing'}
              lazyLoad={"true"}
              alt={"results"}
              imgName={"results_n.webp"}
            />
            <div className="card-heading">Results</div>
            <div className="card-description">Obsessive focus on getting tests shipped and producing results.  Our average result is a 38% lift in 90 days.</div>
          </div>
        </div>
      </div>
      <FormSection />
      <section className="faq-pricing">
        <div className="faq-heading">FAQs</div>
        <div className="faqs-wrap">
          <div className="faq-item">
            <div className="question">Is there a commitment?</div>
            <div className="answer">
              <p>No. There is no commitment.</p>
              <p>We deserve your business only if we are providing exceptional value. If for any reason you feel like we are not delivering or if your circumstances change, you are free to end the engagement.</p>
              <p>Flat fee engagements are month-to-month. You can stop at any time.</p>
              <p>Performance-based engagements, can also be stopped at any time. You have the choice to either pay performance fees owed on any winning test that you chose to keep, or roll back the changes and pay nothing.</p>
            </div>
          </div>
          <div className="faq-item">
            <div className="question">Is there a cap on fees for performance engagements?</div>
            <div className="answer">
              <p>Yes. Fees are capped.</p>
            </div>
          </div>
          <div className="faq-item">
            <div className="question">How is the increase in performance measured? What if we can't agree</div>
            <div className="answer">
              <p>Performance is measured based on the results from the A/B tests. A/B testing measures only the impact of the test changes. It removes other factors like seasonality, underlying growth, and promotions.</p>
              <p>If there is any doubt around results, we are happy to re-run any test (at our expense) and use the re-run results as the basis for fees.</p>
              <p>In the unlikely event that we don't agree, you get to decide.</p>
            </div>
          </div>
          <div className="faq-item">
            <div className="question">Is there any upfront payment for the performance deal?</div>
            <div className="answer">
              <p>No. You only pay at the end of the engagement if we delivered results to your satisfaction.</p>
            </div>
          </div>
          <div className="faq-item">
            <div className="question">How do I know that the extra conversions will be of similar quality?</div>
            <div className="answer">
              <p>We can track the performance of tests down funnel (i.e. MQL, SQL, deal won, etc) to ensure that winning test not only perform better in the primary metric (e.g. form fills), but also perform better in terms of other down-funnel metrics.</p>
            </div>
          </div>
          <div className="faq-item">
            <div className="question">Are there any hidden extras?</div>
            <div className="answer">
              <p>No. Pricing is all inclusive.</p>
              <p>There is no setup fee. We don't require you to purchase any software.</p>
            </div>
          </div>
        </div>
      </section>
      {!isSSR && (
        <Suspense fallback={<FallbackLoading />}>
          <SliderSection />
        </Suspense>
      )
      }
    </span>
  )
}
